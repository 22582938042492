/* body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
} */

.login-label {
  color: #737373;
  fill: solid;
}
.card .border-danger {
  border-color: #ed2e04 !important;
}

.whiteCl {
  color: white !important;
}
.redCl {
  color: #b91414 !important;
}
.redBg {
  background-color: #b91414 !important;
}

.lightRedBg {
  background-color: #fce7e7 !important;
}

.dropdown-item {
  padding: 0.5rem 2.5rem;
  /* font-weight: 600; */
  background: #f8f7f7;
}

.phone {
  border: none;
  padding-left: 50px !important;
  /* border-bottom-style: groove !important; */
  /* border-bottom-color: #8CC63E !important; */
}

.phone::placeholder {
  font-size: 1.7rem;
}

.submit {
  display: block;
  margin: auto;
  margin-top: 30px !important;
  border-radius: 30px !important;
  height: 50px;
  width: 250px !important;
  font-size: 1.2rem !important;
  background: linear-gradient(270deg, #c12026 36.03%, #ed2e04 81.99%);
  background-size: 200% 200%;
  -webkit-animation: Animation 5s ease infinite;
  -moz-animation: Animation 5s ease infinite;
  animation: Animation 5s ease infinite;
}

.create-team {
  display: block;
  margin: auto;
  margin-top: 20px !important;
  border-radius: 30px !important;
  height: 50px;
  width: 250px !important;
  font-size: 1.2rem !important;
  background-size: 200% 200%;
  -webkit-animation: Animation 5s ease infinite;
  -moz-animation: Animation 5s ease infinite;
  animation: Animation 5s ease infinite;
  border: 1px solid #b91414;
}

.submitHollow {
  display: block;
  margin: auto;
  margin-top: 30px !important;
  border-radius: 30px !important;
  height: 65px;
  width: 250px !important;
  font-size: 1.6rem !important;
  background-color: transparent !important;
  border: 1px solid #c12026 !important;
  color: #c12026 !important;
}

.submitHollow1 {
  display: block;
  margin: auto;
  border-radius: 30px !important;
  background-color: transparent !important;
  border: 1px solid #c12026 !important;
  color: #c12026 !important;
}

.confirm {
  display: block;
  margin: auto;
  margin-top: 30px !important;
  border-radius: 30px !important;
  height: 45px;
  width: 150px !important;
  font-size: 1.2rem !important;
  background: linear-gradient(270deg, #c12026 36.03%, #ed2e04 81.99%);
  background-size: 200% 200%;
  -webkit-animation: Animation 5s ease infinite;
  -moz-animation: Animation 5s ease infinite;
  animation: Animation 5s ease infinite;
}

.telco-input {
  background: rgb(248, 247, 247);
  border: none;
  width: 100%;
  height: 65px;
  padding-left: 15px;
  border-collapse: separate;
  border-left: 1px solid #e6e7e8;
  font-size: 1.7rem;
  outline: none;
  border-spacing: 15px 50px;
  font-size: 1.7rem;
}

.payout-input {
  background: rgb(248, 247, 247);
  border: none;
  width: 100%;
  height: 45px;
  padding-left: 15px;
  border-collapse: separate;
  border-left: 1px solid #e6e7e8;
  font-size: 1.2rem;
  outline: none;
  border-spacing: 15px 50px;
}

.payout-input-cnic {
  background: rgb(248, 247, 247);
  border: none;
  width: 100%;
  height: 65px;
  padding-left: 15px;
  border-collapse: separate;
  border-left: 1px solid #e6e7e8;
  font-size: 1.7rem;
  outline: none;
  border-spacing: 15px 50px;
}

.fa-angle-down {
  margin-top: 5px;
}

@-webkit-keyframes Animation {
  0% {
    background-position: 10% 0%;
  }
  50% {
    background-position: 91% 100%;
  }
  100% {
    background-position: 10% 0%;
  }
}
@-moz-keyframes Animation {
  0% {
    background-position: 10% 0%;
  }
  50% {
    background-position: 91% 100%;
  }
  100% {
    background-position: 10% 0%;
  }
}
@keyframes Animation {
  0% {
    background-position: 10% 0%;
  }
  50% {
    background-position: 91% 100%;
  }
  100% {
    background-position: 10% 0%;
  }
}

#bundle-box {
  height: 65px;
  width: 100%;
  box-shadow: 0px 3px 6px #dceaf1;
  cursor: pointer;
  border-radius: 5px;
}

#bundle-box:hover {
  background-color: lightgray;
}

.bundle-text {
  padding-top: 10px;
  padding-bottom: 0px;
  font-size: 18px;
  font-weight: 600;
}

.telco-logo-image {
  height: 100%;
  width: 100%;
  align-self: center;
  vertical-align: middle;
}

.bundle-subtext {
  padding-left: 20px;
  color: #b91414;
  font-weight: 600;
}

.bundle-price {
  padding-top: 5px;
  padding-right: 0px;
  text-align: right;
  font-weight: bold;
  font-size: 24px;
}

.fa-check-circle {
  font-size: 16px;
}

.telco-price-point {
  padding-top: 10px;
  padding-right: 0px;
  text-align: right;
  font-weight: 600;
}

@media screen and (min-width: 441px) {
  .telco-price-point {
    font-size: 18px;
  }
}

/* If the screen size is 600px wide or less, set the font-size of <div> to 30px */
@media screen and (max-width: 440px) {
  .telco-price-point {
    font-size: 14px;
  }
  .telco-logo-image {
    margin-top: 5px;
  }
}

.clickable-text {
  cursor: pointer;
  color: #b91414;
}

.unclickable-text {
  color: #737373;
  opacity: 0.4;
}

.modal-body-easypaisa {
  height: auto;
}

.holds-the-iframe {
  background: url(/images/loader_v2.gif) center center no-repeat;
}
