.mbrules {
  list-style: none;
}
.info p {
  font-size: 14px;
}
.table-width {
  width: 50%;
}

.payout-btn {
  width: 80%;
  height: 50px;
  border: none;
  font-size: 18px;
  font-weight: bold;
}

.accord-btn {
  box-sizing: border-box;
  border-radius: 10px;
  background: none;
  width: 100%;
  height: 60px;
}

.gray-leaderbaord-image {
  background: url("/images/trivia/graycircle.png") no-repeat;
  position: relative;
  height: 40px;
  padding-top: 5px;
}

.wallet-card {
  width: 329px;
  height: max-content;
  max-height: 400px;
  background: #ffffff;
  mix-blend-mode: normal;
  background-image: url("../images/bg.png");
  background-position: center;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
}
.wallet-button {
  width: 187px;
  height: 42px;
  background: #b91414;
  mix-blend-mode: normal;
  border-radius: 79px;
  cursor: pointer;
}
.contest-wallet-button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 80px;
  height: 32px;
  /* background: #b91414; */
  mix-blend-mode: normal;
  border-radius: 79px;
  border: 1px solid #B91414;
  cursor: pointer;
}
.transaction-status {
  /*background: #ffe3ce;*/
  border-radius: 5px;
}

.google-ad {
  max-width: 390px;
  height: 181px;
  background: #5e1212;
}
.wallet-view-transaction {
  width: 100%;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
  border-radius: 0px;
  mix-blend-mode: normal;
  background-color: #ffffff;
  /* height: 500px; */
}

.main-section-too {
  background-image: url(/images/trivia/bg-red.png);
  background-repeat: no-repeat;
  background-color: wheat;
}
.small-circle {
  position: absolute;
  top: 0px;
  left: 16%;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background: #ffffff;
  border: 2px solid #bf1f1f;
  box-sizing: border-box;
  font-size: 10px !important;
}

.status-circle {
  position: absolute;
  top: 0px;
  left: 27%;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  font-size: 10px !important;
  line-height: 27px;
  text-align: center;
  background: #ffffff;
  box-sizing: border-box;
  border: 2px solid #bf1f1f;
}

.navigation {
  width: 100%;
  overflow: hidden;
  overflow-x: scroll;
  /* scroll-behavior: smooth; */
  scrollbar-color: transparent;
  font-size: 14px;
  color: white;
  height: 80px;
}
.navigation .active {
  border-bottom: 3px solid white;
  /* padding: 5px; */
}

.navigation::-webkit-scrollbar {
  width: 0px; /* Remove scrollbar space */
  background: transparent; /* Optional: just make scrollbar invisible */
}
ul.thumbs {
  margin: 0;
  padding: 0;
  list-style: none;
  white-space: nowrap;
  color: white;
}
ul.thumbs li {
  display: inline-block;
}

.score-bg {
  width: 74px;
  height: auto;
  background: #5e1212;

  border-radius: 28px;
}

.profiling-img-bg {
  border: 2px solid white;
  border-radius: 50%;
}

.t-20-bg {
  width: 100%;
  /* height: 234px; */

  background: linear-gradient(
      360deg,
      rgba(255, 230, 0, 0.8) -67.47%,
      rgba(255, 244, 144, 0.8) -10.17%,
      rgba(255, 255, 255, 0.8) 34.23%
    ),
    #ffffff;
  /* Equal */

  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
}
.odi-bg {
  width: 100%;
  /* height: 234px; */

  background: linear-gradient(
      0.46deg,
      rgba(40, 206, 44, 0.5) -42.69%,
      rgba(255, 255, 255, 0.5) 27.79%
    ),
    #ffffff;
  /* Equal */

  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
}
.test-bg {
  width: 100%;
  /* height: 234px; */

  background: linear-gradient(
      360deg,
      rgba(206, 0, 0, 0.5) -63.01%,
      rgba(255, 255, 255, 0.5) 43.83%
    ),
    #ffffff;
  /* Equal */

  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
}

.horizontal-sperator {
  height: 50px;
  opacity: 0.2;
  border: 1px solid #000000;
  display: block;
  width: 0px;
  margin: auto;
}

.yellow-btn {
  padding: 8px 41px;
  /* width: 146px; */
  /* height: 35px; */

  background: #ffc300;
  border-radius: 40px;

  font-weight: bold;
  font-size: 14px;
}

.fill-bg {
  width: 124px;
  height: 14px;
  background: #b6b6b6;
  border-radius: 75px;
}

.slick-dots li.slick-active button:before {
  opacity: 0.75;
  color: white;
}

.slick-dots li {
  margin: 0px -5px;
}

@media (max-width: 575.98px) {
  .yellow-btn {
    font-size: 12px;
  }
}

@media (min-width: 576px) and (max-width: 767.98px) {
  .yellow-btn {
    font-size: 12px;
  }
  .navigation {
    font-size: 14px;
  }
}

@media (min-width: 768px) and (max-width: 991.98px) {
  .yellow-btn {
    font-size: 16px;
  }
  .navigation {
    font-size: 14px;
  }
}

@media (min-width: 992px) and (max-width: 1199.98px) {
  .yellow-btn {
    font-size: 18px;
  }
  .navigation {
    font-size: 16px;
  }
}

@media (min-width: 1200px) {
  .yellow-btn {
    font-size: 18px;
  }
  .navigation {
    font-size: 16px;
  }
}

.modal-body-social {
  background-image: url("../images/Background.png");
  background-repeat: no-repeat;
  background-position: center; /* Center the image */
  background-size: cover; /* Resize the background image to cover the entire container */
  height: 660px;
}

.head {
  font-family: "Patua One", cursive;
  font-style: normal;
  font-weight: normal;
  font-size: 25px;
  line-height: 20px;
  text-align: center;
  color: #3e3e3e;
  margin-top: 20px;
}
.pocket {
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 20px;
  text-align: center;
  margin-top: 8%;
  color: #3e3e3e;
}

.easypaisa-pocket {
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-size: 16px;
  line-height: 16px;
  text-align: left;
  margin-top: 8%;
  color: #3e3e3e;
}

.easypaisa-pocket-ul {
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-size: 12px;
  line-height: 16px;
  text-align: left;
  color: #3e3e3e;
}

.social_buttons {
  margin-top: 15%;
}
.twitter {
  margin-top: 4%;
}
.gmail {
  margin-top: 4%;
}
@media only screen and (min-device-width: 1024px) and (max-device-height: 1366px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: portrait) {
  .fb {
    width: 35%;
  }
  .gm {
    width: 35%;
  }
  .social_buttons {
    margin-top: 10%;
  }
  .tw {
    width: 35%;
  }
  .twitter {
    margin-top: 2%;
  }
  .gmail {
    margin-top: 2%;
  }
}
@media only screen and (min-device-width: 768px) {
  .fb {
    width: 35%;
  }
  .gm {
    width: 35%;
  }
  .social_buttons {
    margin-top: 10%;
  }
  .tw {
    width: 35%;
  }
  .twitter {
    margin-top: 2%;
  }
  .gmail {
    margin-top: 2%;
  }
}
@media only screen and (min-width: 1200px) {
  .fb {
    width: 35%;
  }
  .gm {
    width: 35%;
  }
  .social_buttons {
    margin-top: 10%;
  }
  .tw {
    width: 35%;
  }
  .twitter {
    margin-top: 2%;
  }
  .gmail {
    margin-top: 2%;
  }
}
@media only screen and (max-width: 400px) {
  .fb {
    width: 35%;
  }
  .gm {
    width: 35%;
  }
  .social_buttons {
    margin-top: 10%;
  }
  .tw {
    width: 35%;
  }
  .twitter {
    margin-top: 2%;
  }
  .gmail {
    margin-top: 2%;
  }
  /* .social-modal {
  height: 500px;
  width: 300px;
}
.modal-body {
  height: 500px;
  width: 300px;
}
.modal-header {
  width: 300px
}
.modal-dialog {
  max-width: 300px;
} */
  .modal-dialog {
    width: 80%;
    margin: auto;
  }
}

.social-modal {
  height: 660px;
  width: 400px;
}

.modal-dialog {
  max-width: 400px;
}
