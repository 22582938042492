#main-nav .container .navbar-brand img.logo{
    width: 59px;
}
nav#main-nav{
    padding-top:12px!important;
    padding-bottom: 12px!important;
}
.text-jazz-login{
    color: #8cc63f !important
}
.divide2:after{
    /* angled color in welcome poup */
    background-color: #8cc63f !important;
}
.btn-primary:hover,
button.submit.bg-red{
    /* button in welcome popup */
    background-color: #696c6f!important;
    border-color:  #696c6f!important;
}
.card .border-danger{
    border-color: #8cc63f !important;
}

.Dropdown-control {
    border: none !important;
    padding: 0 !important;    
}

.Dropdown-arrow {
    display: none !important;
}

.dropdown-menu {
    position: absolute !important;
    right: -133px !important;
    width: max-content !important;
}

.dropdown-menu .active a {
    color: #000000 !important;
    font-weight: bold !important;
}

.dropdown-menu a {
    color: #5d5d5d !important;
}

.dropdown-menu a:hover {
    color: #000000 !important;
}

.Redbg {
    background: #B91414;
}

.Greenbg {
    background: #C5ffd9;
    border-radius: 5px;
}

.user-profile {
    display: flex;
    flex-direction: column;
}

.user,
.user-wallet {
    display: flex;
    justify-content: space-between;
}

.column {
    display: flex;
    flex-direction: column;
}

.msisdn {
    font-size: 16px;
}

.column .text-login {
    opacity: 1;
}

.m-f-14 {
    font-size: 14px !important;
}

.logout {
    display: block;
    margin: auto;
    border-radius: 20px;
    color: #E84F4F;
    border: 1px solid #E84F4F;
    background: white;
}

.input {
    border: 0;
    border-bottom: 1px solid #DCEAF1;
    border-radius: 6px;
    font-size: 24px;
    opacity: 0.5;
    text-align: center;
    padding-top: 8px;
    padding-bottom: 8px;
    width: 100%;
}

.save-profile {
    display: block;
    border-radius: 20px !important;
    background-color: #B91414 !important;
    font-size: 16px;
    width: 200px;
}

.modal-content {
    border-radius: 10px !important;
}

.payout-arrow {
    width: 5px;
    height: 10px;
    transform: matrix(0, -1, 1, 0, 0, 0);
    border: 1px solid #333333;
}

