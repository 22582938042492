.custom-clamp-text-seo a{
    line-height: 1.95rem;
    max-height: 4rem;
    white-space: normal;
    font-size: 1.5rem;
}
.anchor{
    line-height: 1.3rem;
    max-height: 3.4rem;
    white-space: normal;
    font-size: 1rem;
    display: -webkit-box;
    overflow: hidden;
    text-overflow: ellipsis;
}

.custom-clamp-text-seo a:hover,
.anchor:hover{
    color: #006fe6 !important
}
.schedule-seo p{
    font-size: 16px;
    font-weight: 600;
}
.custom-clamp-text-seo h2{
    line-height: 1.95rem;
    max-height: 4rem;
    white-space: normal;
    cursor: pointer;
    font-size: 1.5rem !important
}
.custom-clamp-text-seo h2:hover{
    color: #006fe6
}
.custom-clamp-text-seo p{
    line-height: 1.7rem;
    max-height: 3.4rem;
    font-size: 1.25rem;
    cursor: pointer;
}
.custom-clamp-text-seo p:hover{
    color: #006fe6
}
.overflow-x-auto-seo{
    overflow-x: auto;
}
.overflow-x-auto-seo a{
    white-space: nowrap;
}
.ranking-change-icon-size-seo{
    font-size: 18px;
}