.text-jazz-login{
    color: #ED2E04 !important
}
.divide2:after{
    /* angled color in welcome poup */
    background-color: #ED2E04 !important;
}
.card .border-danger{
    border-color: #ED2E04 !important;
}

#myBtn:hover {
    background-color:#ED2E04!important;
}


.npl {
    padding-left: 0 !important;
}

.npr {
    padding-right: 0 !important;
}


.plist-stack {
    position: absolute;
    left: 0;
    background: black;
    height: 100%;
    z-index: 300;
    left: 0;
    width: 30%;
    opacity: 0.6;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
}

.plist2-stack {
    position: absolute;
    left: 0;
    background: black;
    height: 100%;
    z-index: 300;
    left: 0;
    width: 30%;
    opacity: 0.6;
    border-top-left-radius: 10px;
    
}

.plist-container {
    position: absolute;
    bottom: 0;
    left: 0.7rem;
    right: 0;
}

.plist-container p {
    color: white;
    font-size: 0.8rem;
    font-weight: bold;
    opacity: initial;
}


.plist-container p bold{
    font-weight: bolder;
    font-size: 1.2rem;
}

.plist-icon {
    width: 1.5rem;
}

.carousel-wrapper,
.carousel,
.carousel > .slider-wrapper,
.carousel > .slider-wrapper > .slider {
  height: 100%;
  border-radius: 10px;
}

.carousel-wrapper .carousel-slider .slider {
    height: 100% !important;
}

.more-icon {
    -moz-border-radius: 50%;
    border-radius: 50%;
    background-color: white;
    color: white;
    text-align: center;
    height: 40px;
    margin-top: 40px;
    margin-left: 10px;
    margin-right: 10px;
    border: 1px;
    box-shadow: 0px 0px 5px 1px #bebebe ;
}

@media screen and (max-width: 500px) {
    .plist-icon {
        width: 1.2rem !important;
    }

    .plist-container p {
        color: white;
        font-size: 0.5rem;
        font-weight: bold;
        opacity: initial;
    }
    
    
    .plist-container p bold{
        font-weight: bolder;
        font-size: 1rem;
    }
}

