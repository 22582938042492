.cricwick-ads .carousel-root .carousel .slider-wrapper {
  width: 38% !important;
}

@media screen and (max-width: 400px) {
  .cricwick-ads .carousel-root .carousel .slider-wrapper {
    width: 95% !important;
  }
}
@media screen and (min-width: 400px) {
  .cricwick-ads .carousel-root .carousel .slider-wrapper {
    width: 87% !important;
  }
}
@media screen and (min-width: 500px) {
  .cricwick-ads .carousel-root .carousel .slider-wrapper {
    width: 65% !important;
  }
}

@media screen and (min-width: 668px) {
  .cricwick-ads .carousel-root .carousel .slider-wrapper {
    width: 59% !important;
  }
}
@media screen and (min-width: 732px) {
  .cricwick-ads .carousel-root .carousel .slider-wrapper {
    width: 50% !important;
  }
}
@media screen and (min-width: 860px) {
  .cricwick-ads .carousel-root .carousel .slider-wrapper {
    width: 44% !important;
  }
}
@media screen and (min-width: 960px) {
  .cricwick-ads .carousel-root .carousel .slider-wrapper {
    width: 42% !important;
  }
}
