.divide {
  width: 100%;
  margin: auto;
  width: 100%;
  text-align: center;
  margin: 20px auto 0;
}

@font-face {
  font-family: urdu1;
  src: url("/public/AlQalam\ Taj\ Nastaleeq\ Regular.ttf");
}

.useUrduFont {
  font-family: urdu1;
  font-size: 20px !important;
}

.diagonal {
  text-align: center;
  background-color: #c30507;
  background-image: -webkit-linear-gradient(
    -50deg,
    #c30507 0%,
    #c30507 50%,
    #fff 50%,
    #fff 60%
  );
  background-image: linear-gradient(
    -50deg,
    #c30507 0%,
    #c30507 50%,
    #fff 50%,
    #fff 60%
  );
  height: 200px;
  width: 100%;
}
/* *********************** */
.divide1 {
  background-image: url(./images/popup-em.png);
  background-repeat: no-repeat;
  background-position: right 0;
  background-origin: content-box;
  background-size: cover;
  width: 100%;
  text-align: center;
  margin: 20px auto 0;
}
.divide-content {
  width: 100%;
  min-height: 220px;
}
/* ************** */
.modalDescription {
  max-height: 100% !important;
  line-height: 25px !important;
  font-size: 15px !important;
}
.divide2 {
  width: 100%;
  text-align: center;
  position: relative;
  overflow: hidden;
}
.divide2:after {
  background-color: #c30507;
  content: "";
  height: 330%;
  position: absolute;
  right: -8%;
  top: -90%;
  transform: rotate(30deg);
  width: 50%;
  z-index: 0;
}
.jazz-logo .img-responsive {
  float: left;
  width: 80px;
  padding-top: 15px;
}
.modal-dialog {
  max-width: 710px;
}
.allow {
  width: 300px;
  margin-top: 9px;
  font-size: 23px;
  font-weight: 500;
  background-color: white;
  color: black;
  border: none;
  border-radius: 30px;
}
.allow-login {
  width: 300px;
  margin-top: 25px;
  font-size: 23px;
  font-weight: 500;
  background-color: white;
  color: black;
  border: none;
  border-radius: 30px;
  box-shadow: 0px 0px 2px 0px #d8d8d8;
}
.status {
  font-size: 15px;
  color: black;
  font-weight: 700;
}
.login-dialogue {
  background-color: transparent;
  box-shadow: none;
  max-width: 85%;
  margin: auto;
  border: none;
}
.inner-text {
  padding-top: 10px;
  padding-left: 20px;
}
.jazz-logo .img-responsive {
  float: left;
  width: 90px;
  margin-top: 10px;
  margin-left: 15px;
}
.jazzc {
  width: 165px;
  margin-right: 100px;
}
.inner-text-login {
  padding-top: 20px;
  padding-left: 30px;
}
.text {
  font-size: 22px;
  font-family: "Lato", sans-serif;
  color: black;
  font-weight: 500;
  margin-right: 100px;
}
.text-jazz-login {
  color: #ff0000;
  font-size: 30px;
  line-height: 1.5 !important;
  font-weight: 600;
}
.text-login {
  font-size: 20px;
  font-family: "Lato", sans-serif;
  color: black;
  font-weight: 500;
  opacity: 0.6;
}
.modal-close {
  top: 0px;
  right: 5px;
  position: absolute;
  z-index: 2;
}
@media only screen and (max-width: 767px) {
  .divide-content {
    min-height: 130px;
  }
  .status {
    font-size: 10px;
  }
  .text-jazz-login {
    color: #ff0000;
    font-size: 15px;
  }
  .inner-text-login {
    padding-top: 14px;
    padding-bottom: 5px;
    padding-left: 10px;
  }
  br {
    line-height: 1;
  }
  .jazzc {
    width: 70px;
    margin-right: 70px;
  }
  .jazz-logo .img-responsive {
    width: 45px;
    margin-top: 15px;
    margin-left: 25px;
  }
  .text {
    font-size: 10px;
    margin-right: 70px;
  }
  .allow {
    width: 119px;
    font-size: 13px;
    height: 24px;
    padding: 0px;
    margin-top: 7px;
  }
  .allow-login {
    width: 219px;
    font-size: 16px;
    height: 24px;
    padding: 0px;
    margin-top: 3px;
  }
  .text-login {
    font-size: 14px;
  }
}
