.main-bg-ob {
  position: fixed;
  z-index: 100;
  width: 100%;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5));
  filter: blur(4px);
  height: 1500px;
  top: 0;
  left: 0;
}

.ob-container {
  z-index: 101;
  position: fixed;
  height: 90vh;
  width: 80%;
  left: 13%;
  top: 30px;
  margin: auto;
}

.main-slider {
  padding-top: 12%;
  width: 95%;
}
.ob-logo {
  z-index: 5;
  position: absolute;
  top: -66px;
  left: 50%;

  transform: translateX(-50%);
}
.ob-text {
  color: white;
}
.ob-text2 {
  color: white;
}
.omni-present {
  background: #bf1f1f;
  /* Equal */
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
  border-radius: 10px 0px 0px 10px;
  width: 43px;
  position: fixed;
  right: 0px;
  top: 70%;
  z-index: 1;
}
.omni-present-left {
  position: fixed;
  top: 66vh;
  right: 50px;
  width: 226px;
  z-index: 1;
}
.omni-present-text-box {
  position: absolute;
  top: 14px;
  left: 12px;
  font-size: 14px;
}

.terms-conditions {
  margin-top: 31px;
  font-size: 12px;

  position: absolute;
  color: white;
  opacity: 0.4;
  left: 50%;
  transform: translateX(-50%);
}

@media (max-width: 575.98px) {
  .ob-container {
    width: 90vw;
    left: 5vw;
    top: 10vh;
    height: 80vh;
  }
  .ob-text {
    font-size: 4.2vw;
    margin-bottom: 1rem;
  }
  .ob-text2 {
    font-size: 4.2vw;
  }
}

@media (min-width: 576px) and (max-width: 767.98px) {
  .ob-container {
    width: 80%;
    left: 13%;
  }
  .ob-text {
    font-size: 14px;
    margin-bottom: 1rem;
  }
  .ob-text2 {
    font-size: 14px;
  }
}

@media (min-width: 768px) and (max-width: 991.98px) {
  .ob-container {
    width: 40%;
    left: 30%;
  }
  .ob-text {
    font-size: 14px;
    margin-bottom: 1rem;
  }
  .ob-text2 {
    font-size: 14px;
  }
}

@media (min-width: 992px) and (max-width: 1199.98px) {
  .ob-container {
    width: 34%;
    left: 34%;
  }
  .ob-text {
    font-size: 16px;
    margin-bottom: 1rem;
  }
  .ob-text2 {
    font-size: 18px;
  }
}

@media (min-width: 1200px) {
  .ob-container {
    width: 25%;
    left: 36%;
    top: 45px;
  }
  .ob-text {
    font-size: 18px;
    margin-bottom: 2.5rem;
  }
  .ob-text2 {
    /* padding: 5px; */
    /* margin-top: 25px; */
    font-size: 17px;
  }
}
